const SpecificityLocales = [
  {
    title: {
      ua: 'Теплова енергетика',
      ru: 'Тепловая энергетика',
      en: 'Thermal energy'
    },
    content: {
      ua: ["Наша компанія протягом більше 20 років виконує роботи з ремонту електродвигунів для теплових станцій України..."],
      ru: ["Наша компания на протяжении более 20 лет выполняет работы по ремонту электродвигателей для тепловых станций Украины..."],
      en: ["For over 20 years, our company has been repairing electric motors for thermal stations in Ukraine..."]
    },
    link: "/our_partners/#partner-1",
    image: "thermal_energy.jpeg",
  },
  {
    title: {
      ua: 'Гідроенергетика',
      ru: 'Гидроэнергетика',
      en: 'Hydro energy'
    },
    content: {
      ua: ["Однією з найбільших проблем гідроенергетики - це старі гідрогенератори, які вводилися в експлуатацію в..."],
      ru: ["Одной из самых больших проблем гидроэнергетики – это старые гидрогенераторы, которые вводились в эксплуатацию в..."],
      en: ["One of the biggest problems of hydroelectricity is the old hydrogenerators, which were commissioned in..."]
    },
    link: "/our_partners/#partner-2",
    image: "hydro_energy.jpeg"
  },
  {
    title: {
      ua: 'Атомна енергетика',
      ru: 'Атомная энергетика',
      en: 'Nuclear energy'
    },
    content: {
      ua: ["Основним принципом роботи з атомними електростанціями є чітка відповідність вимогам Замовника. Так на протязі..."],
      ru: ["Основным принципом работы с Атомными электростанциями является четкое соответствие требованиям Заказчика. Так на протяжении..."],
      en: ["The main principle of work with nuclear power plants is a clear compliance with the requirements of the customer. So throughout..."]
    },
    link: "/our_partners/#partner-3",
    image: "nuclear_energy.jpeg"
  },
  {
    title: {
      ua: 'Цементні підприємства',
      ru: 'Цементные предприятия',
      en: 'Cement enterprises'
    },
    content: {
      ua: ["Для цементних підприємств безперебійна робота електродвигунів - є одним з ключових пріоритетів в..."],
      ru: ["Для цементных предприятий бесперебойная работа электродвигателей – является одним из ключевых приоритетов в..."],
      en: ["For cement enterprises, the uninterrupted operation of electric motors is one of the key priorities in...s  "]
    },
    link: "/our_partners/#partner-4",
    image: "cement_enterpris.jpeg"
  },
  {
    title: {
      ua: 'Хімічні підприємства',
      ru: 'Химические предприятия',
      en: 'Chemical enterprises'
    },
    content: {
      ua: ["На підприємствах хімічної промисловості встановлена велика кількість крупних електричних двигунів..."],
      ru: ["На предприятиях Химической промышленности установлено большое количество крупных электрических двигателей..."],
      en: ["A large number of large electric motors are installed at the enterprises of the chemical Industry..."]
    },
    link: "/our_partners/#partner-5",
    image: "chemical_enterprises.jpeg"
  },
  {
    title: {
      ua: 'Газовидобувні підприємства',
      ru: 'Газодобывающие предприятия',
      en: 'Gas producing enterprises'
    },
    content: {
      ua: ["З кожним роком в Україні збільшуються обсяги видобутку природного газу. Виконуючи капітальні ремонти електродвигунів..."],
      ru: ["С каждым годом в Украине увеличиваются объемы добычи природного газа. Выполняя капитальные ремонты электродвигателей..."],
      en: ["Every year the volume of natural gas production increases in Ukraine. Performing major repairs of electric motors..."]
    },
    link: "/our_partners/#partner-6",
    image: "gas_producing_enterprises.jpeg"
  },
  {
    title: {
      ua: 'Металургійні підприємства',
      ru: 'Металлургические предприятия',
      en: 'Metallurgical enterprises'
    },
    content: {
      ua: ["Металургійні комбінати насичені електричними машинами як постійного, так і змінного струму, які працюють..."],
      ru: ["Металлургические комбинаты насыщены электрическими машинами как постоянного, так и переменного тока, которые работают..."],
      en: ["Metallurgical plants are saturated with electric machines, both direct and alternating current, which operate..."]
    },
    link: "/our_partners/#partner-7",
    image: "metallurgical_enterprises.jpeg"
  },
  {
    title: {
      ua: 'Вугільні компанії',
      ru: 'Угольные компании',
      en: 'Coal companies'
    },
    content: {
      ua: ["Важливість безперебійної роботи електричних двигунів, які перебувають у вугільних шахтах - це в першу чергу..."],
      ru: ["Важность бесперебойной работы электрических двигателей, находящихся в угольных шахтах – это в первую очередь..."],
      en: ["The importance of the smooth operation of electric motors in coal mines is primarily..."]
    },
    link: "/our_partners#partner-8",
    image: "coal_companies.jpeg"
  },
  {
    title: {
      ua: 'Гірничо-збагачувальні підприємства',
      ru: 'Горнообогатительные предприятия',
      en: 'Mining and processing enterprises'
    },
    content: {
      ua: ["Одними з перших наших замовників були Гірничо-збагачувальні підприємства, велика кількість яких знаходиться..."],
      ru: ["Одними из первых наших заказчиков были Горно обогатительные предприятия, большое количество которых находится..."],
      en: ["One of our first customers were mining and processing enterprises, a large number of which are located..."]
    },
    link: "/our_partners/#partner-9",
    image: "mining.webp"
  },
  {
    title: {
      ua: 'Залізна дорога',
      ru: 'Железная дорога',
      en: 'Railway'
    },
    content: {
      ua: ["Одним з основних критеріїв безпечних перевезень пасажирів і вантажів - є регулярне обслуговування та ремонт..."],
      ru: ["Одним из основных критериев безопасных перевозок пассажиров и грузов – является регулярное обслуживание и ремонт..."],
      en: ["One of the main criteria for the safe transport of passengers and goods is regular maintenance and repair..."]
    },
    link: "/our_partners/#partner-10",
    image: "railway.jpeg"
  },
  {
    title: {
      ua: 'Нафтопереробні підприємства',
      ru: 'Нефтеперерабатывающие предприятия',
      en: 'Oil refineries'
    },
    content: {
      ua: ["Нафтопереробні компанії - це підприємства підвищеної небезпеки з найвищими вимогами щодо допуску..."],
      ru: ["Нефтеперерабатывающие компании – это предприятия повышенной опасности с наивысшими требованиями по допуску..."],
      en: ["Oil refineries are high-risk enterprises with the highest requirements for admission..."]
    },
    link: "/our_partners/#partner-11",
    image: "oil_refineries.jpeg"
  },
  {
    title: {
      ua: 'Машинобудівні підприємства',
      ru: 'Машиностроительные предприятия',
      en: 'Machine-building enterprises'
    },
    content: {
      ua: ["Точність робіт в машинобудуванні забезпечується приводами постійного струму, в тому числі імпортного..."],
      ru: ["Точность работ в машиностроении обеспечивается приводами постоянного тока, в том числе импортного..."],
      en: ["The accuracy of work in mechanical motorering is provided by DC drives, including imported..."]
    },
    link: "/our_partners/#partner-12",
    image: "machine_building_enterprises.jpeg"
  },
];


export const SpecificityLocalesTitle =  {
  ru: {
    title: "МЫ ЗНАЕМ ВАШУ СПЕЦИФИКУ",
    content: "Наша компания имеет огромный опыт работы со всеми отраслями промышленности.  Мы знаем и понимаем проблемы, которые могут возникать на Вашем производстве и у нас есть рабочие стратегии абсолютно для каждого из нижеперечисленных сфер бизнеса:"
  },
  en: {
    title: "WE KNOW YOUR SPECIFICITY",
    content: "Our company has vast experience working with all industries. We know and understand the problems that may arise in your production and we have working strategies for absolutely each of the following business areas:"
  },
  ua: {
    title: "МИ ЗНАЄМО ВАШУ СПЕЦИФІКУ",
    content: "Наша компанія має величезний досвід роботи з усіма галузями промисловості. Ми знаємо та розуміємо проблеми, які можуть виникати на Вашому виробництві і у нас є робочі стратегії абсолютно для кожного з перелічених сфер бізнесу:"
  }
}

export default SpecificityLocales;
import { useRouter } from 'next/router';
import { memo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import ButtonAsLink from 'src/components/ButtonAsLink/ButtonAsLink';
import HomeHeaderLocales from './locales/data';
import 'swiper/css';

const HomeHeaderSlider = ({ setCurrentImg }) => {
  const { locale } = useRouter();

  return (
    <div className="px-4 lg:px-10">
      <Swiper
        modules={[Autoplay]}
        loop
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={(s) => setCurrentImg(s?.realIndex)}
      >
        {HomeHeaderLocales[locale].map(({ title, content, link }) => (
          <SwiperSlide key={title}>
            <div className="text-center xl:text-left">
              <p className="text-2xl xl:text-3xl font-medium mb-2">{title}</p>
              <p className="pb-4 text-base xl:text-xl">{content}</p>
              <ButtonAsLink
                link={link}
                styling="secondary_reverse"
                locales={{
                  en: 'More Details',
                  ru: 'Подробнее',
                  ua: 'Докладніше',
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default memo(HomeHeaderSlider);

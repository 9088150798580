import { forwardRef } from "react";

const Tabs = ({ data= [], setActiveTab, activeTab }, ref) => {
  const handleButton = (index) => {
    if (ref) {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        // eslint-disable-next-line react/destructuring-assignment
        top: ref.current.offsetTop
      });
    }
    setActiveTab(index)
  }

  return (
    <div className="flex flex-row justify-center items-center w-full md:w-auto mt-6">
      {data.map((tab, index) => (
        <div
          key={tab}
          className={`w-1/3 md:min-w-[200px] text-center border-primary uppercase inline-flex border-b-2 mx-2 md:mx-4 
          focus:outline-none hover:border-opacity-100 self-stretch justify-center items-center py-2
          ${activeTab === index ? "" : "border-opacity-40 opacity-40 hover:border-secondary hover:opacity-100 hover:text-secondary"}`}
          role="button"
          tabIndex="0"
          onClick={() => handleButton(index)}
          onKeyDown={() => handleButton(index)}
        >
          <span className="font-semibold text-xs md:text-base" >
            {tab}
          </span>
        </div>
      ))}
    </div>
  )
};

export default forwardRef(Tabs);
import StatisticItem from "src/components/Sections/HomePage/StatisticsCount/components/StatisticItem";
import { useInView } from 'react-intersection-observer'
import { useRouter } from "next/router";
import Items from "./constants/data";

const StatisticsCount = ({ data }) => {
  const { locale } = useRouter();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5
  });

  const countData = data ?? Items;

  return (
    <section ref={ref} className="container mx-auto px-4 py-8 xl:py-20">
      {inView && (
        <ul className="flex flex-wrap justify-between">
          {countData.map((item) => (
            <StatisticItem key={item.content[locale]} item={item}/>
          )
        )}
      </ul>)}
    </section>
  )
};

export default StatisticsCount;
import React from 'react';
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from "next/image";
import { useRouter } from "next/router";
import AnimationHeading from "src/components/AnimationsTags/AnimationHeading/AnimationHeading";
import partners from "./constants/data";
import PartnersLocales from "./locales/data"

const Partners = () => {
  const { locale } = useRouter();
  return (
    <section className="px-4 py-10 xl:py-20">
      <div className="container mx-auto">
        <AnimationHeading
          title={PartnersLocales[locale].title}
          size="text-2xl xl:text-4xl"
          additionalStyle="lg:text-center uppercase"
          margin="text-center mb-8"
        />
        <Swiper
          modules={[Autoplay, Pagination]}
          loop
          autoplay={{
            "delay": 3000,
            "disableOnInteraction": false
          }}
          slidesPerView={1}
          pagination={{
            'el': '#partners_pagination',
            'bulletActiveClass': 'custom-swiper-pagination-bullet-active',
            'bulletClass': 'custom-swiper-pagination-bullet',
          }}
          breakpoints={{
            "640": {
              "slidesPerView": 2,
              "slidesPerGroup": 2,
              "spaceBetween": 20,
            },
            "768": {
              "slidesPerView": 3,
              "slidesPerGroup": 3,
              "spaceBetween": 50,
              'pagination': false
            },
            "1024": {
              "slidesPerView": 4,
              "slidesPerGroup": 4,
              "spaceBetween": 50,
              'pagination': false
            }
          }}
        >
          {partners.map((i) => (
            <SwiperSlide key={i}>
              <div className="w-full h-[100px] relative">
                <Image
                  src={`/assets/sections/home/partners/${i}`}
                  alt=""
                  layout="fill"
                  objectFit="contain"
                  className="backdrop-grayscale"
                />
              </div>
            </SwiperSlide>
          ))}
          <div id="partners_pagination" className="text-center mt-4" />
        </Swiper>
      </div>
    </section>
  );
};

export default Partners;
import { useInView } from "react-intersection-observer";

const AnimationParagraph = ({
  additionalStyle = "",
  children
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    delay: 500,
  });

  return (
    <div
      ref={ref}
      className={`transition duration-1000 ease-in-out ${inView ? "opacity-100" : "opacity-0"} ${additionalStyle}`}
    >
      {children}
    </div>
  );
};

export default AnimationParagraph;
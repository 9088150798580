const data = {
  locales: {
    ru: {
      title: "О КОМПАНИИ",
      content_1: 'ООО ПКФ «Электропромремонт» основана в 1998 году и занимает лидирующие позиции на рынке технического обслуживания и ремонта электрических двигателей, генераторов, а также поставки запасных частей к ним для предприятий, расположенных в Украине и за ее пределами.',
      content_2: 'Основными видами деятельностями нашего предприятия являются: капитальные, средние и текущие ремонты электродвигателей всех мощностей и конструкций, изготовление запасных частей к электродвигателям, и диагностика электрооборудования'
    },
    en: {
      title: "ABOUT COMPANY",
      content_1: 'PKF Elektropromremont LLC was founded in 1998 and occupies a leading position in the market for maintenance and repair of electric motors, generators, as well as the supply of spare parts for them for enterprises located in Ukraine and abroad.',
      content_2: 'The main activities of our company are: overhaul, medium and current repairs of electric motors of all capacities and structures, manufacture of spare parts for electric motors, and diagnostics of electrical equipment.'
    },
    ua: {
      title: "ПРО КОМПАНІЮ",
      content_1: 'ТОВ ВКФ «Електропромремонт» заснована в 1998 році і займає лідируючі позиції на ринку технічного обслуговування та ремонту електричних двигунів, генераторів, а також постачання запасних частин к ним для підприємств які розташовані в Україні та за її межами.',
      content_2: 'Основними видами діяльностями нашого підприємства є: капітальні, середні та поточні ремонти електродвигунів всіх потужностей і конструкцій, виготовлення запасних частин до електродвигунів, і діагностика електрообладнання.'
    }
  }
}

export default data;

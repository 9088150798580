const Items = [
  {
    start: 100,
    end: 15000,
    step: 100,
    content: {
      ru: 'Успешно выполненных заказов',
      ua: 'Успішно виконаних замовлень',
      en: 'Successfully completed orders'
    },
  },
  {
    start: 100,
    end: 10000,
    step: 75,
    content: {
      ru: 'МВт отремонтированных электродвигателей',
      ua: 'МВт відремонтованих електродвигунів',
      en: 'MW of repaired electric motors'
    },
  },
  {
    start: 10000,
    end: 255000,
    step: 1500,
    content: {
      ru: 'Трудочасов выездных работ',
      ua: 'Трудогодин виїзних робіт',
      en: 'Hours of field work'
    },
  },
  {
    start: 100,
    end: 9000,
    step: 60,
    content: {
      ru: 'Тонн изготовленных секций',
      ua: 'Тонн виготовлених секцій',
      en: 'Tons of manufactured sections'
    },
  },
  {
    start: 2,
    end: 1500,
    step: 10,
    content: {
      ru: 'Довольных клиентов',
      ua: 'Задоволених клієнтів',
      en: 'Satisfied customers'
    },
  },


];

export default Items;

export default {
  ru: {
    title: " Наши Партнеры",
    content: "Компания Электропромремонт означает качество и компетентность в ремонте электрических двигателей. Доверие наших клиентов обусловлено тем фактором, что мы оказываем наши услуги качественно и в оговоренные сроки. Наши заказчики могут рассчитывать на максимальное качество и надежность наших услуг на всех этапах ремонта – от диагностики электрических двигателей, изготовлении запасных частей и комплектующих, ремонте до ввода в эксплуатацию. Вся компания была сертифицирована в соответствии с международным стандартом ISO 9001:2015. Так же наша компания имеет целый ряд специальных разрешений, позволяющих выполнять работы повышенной опасности как на нашей производственной площадке, так и на территории Заказчика."
  },
  en: {
    title: "Our Partners",
    content: "Elektropromremont means quality and competence in the repair of electric motors. Trust of our clients is caused by that factor that we render our services qualitatively and in the stipulated terms. Our customers can count on the maximum quality and reliability of our services at all stages of repair - from diagnostics of electric motors, about_us of spare parts and accessories, repair to commissioning. The whole company has been certified according to the international standard ISO 9001: 2015. Also our company has a number of special permissions allowing to carry out works of the increased danger both on our about_us site, and in the territory of the customer."
  },
  ua: {
    title: "Наші Партнери",
    content: "Компанія Електропромремонт означає якість і компетентність в ремонті електричних двигунів. Довіра наших клієнтів обумовлено тим фактором, що ми надаємо наші послуги якісно і в обумовлені терміни. Наші замовники можуть розраховувати на максимальну якість і надійність наших послуг на всіх етапах ремонту - від діагностики електричних двигунів, виготовленні запасних частин і комплектуючих, ремонті до введення в експлуатацію. Вся компанія була сертифікована відповідно до міжнародного стандарту ISO 9001:2015. Так само наша компанія має цілий ряд спеціальних дозволів, що дозволяють виконувати роботи підвищеної небезпеки як на нашому виробничому майданчику, так і на території Замовника."
  }
}


import { useRouter } from "next/router";
import WhoWeAreLocales from "./locales/data"

const WhoWeAre = () => {
  const { locale } = useRouter();

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 smooth-transition">
      {WhoWeAreLocales.map((item) => (
        <div
          key={item[locale].title}
          className="w-full xl:w-[400px] py-4 px-2 flex flex-row"
        >
          <div>
            <p className="w-20 h-20">
              {item.icon || <div>No icon</div>}
            </p>
          </div>

          <div className="flex flex-col p-2">
            <p className="uppercase font-semibold md:min-h-[3rem] py-0">
              {item[locale].title}
            </p>
            <p className="py-1">
              {item[locale].content}
            </p>
          </div>

        </div>
      ))}
    </div>
  );
};

export default WhoWeAre;
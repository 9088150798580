const SpecializationLocales = [
  {
    title: {
      ua: 'Ремонт асинхронних електродвигунів',
      ru: 'Ремонт асинхронных электродвигателей',
      en: 'Repair of asynchronous electric motors'
    },
    list: {
      ua: ["Ремонт електродвигунів з ізоляцією «Моноліт»", "Ремонт безклінових електродвигунів", "Ремонт електродвигунів вибухонебезпечного виконання", "Ремонт електродвигунів з фазним ротором", "Ремонт електродвигунів з короткозамкненим ротором"],
      ru: ["Ремонт электродвигателей с изоляцией «Монолит»", "Ремонт безклиновых электродвигателей", "Ремонт электродвигателей взрывозащищенного исполнения", "Ремонт электродвигателей с фазным ротором", "Ремонт электродвигателей с короткозамкнутым ротором"],
      en: ['Repair of electric motors with insulation "Monolith"', "Repair of wedge-free electric motors", "Repair of explosion-proof electric motors", "Repair of electric motors with a phase rotor", "Repair of electric motors with squirrel-cage rotor"]
    },
    link: "/services/#service-1",
    image: "electric_motor_async.jpeg"
  },
  {
    title: {
      ua: 'Ремонт синхронних електродвигунів',
      ru: 'Ремонт синхронных электродвигателей',
      en: 'Repair of synchronous electric motors'
    },
    list: {
      ua: ["Ремонт електродвигунів з явно вираженими полюсами", "Ремонт синхронних гідрогенераторів", "Ремонт турбодвигунів і турбогенераторів", "Ремонт тягових синхронних генераторів"],
      ru: ["Ремонт электродвигателей с явно выраженными полюсами", "Ремонт синхронных гидрогенераторов", "Ремонт турбодвигателей и турбогенераторов", "Ремонт тяговых синхронных генераторов"],
      en: ["Repair of electric motors with pronounced poles", "Repair of synchronous hydro generators", "Repair of turbo motors and turbo generators", "Repair of traction synchronous generators"]
    },
    link: "/services/#service-2",
    image: "electric_motor_sync.jpeg"
  },
  {
    title: {
      ua: 'Ремонт електродвигунів постійного струму',
      ru: 'Ремонт электродвигаталей постоянного тока',
      en: 'Repair of direct current electric motors'
    },
    list: {
      ua: ["Ремонт електродвигунів постійного струму потужністю до 10 МВт", "Ремонт тягових електродвигунів", "Ремонт генераторів постійного струму"],
      ru: ["Ремонт электродвигателей постоянного тока мощностью до 10 МВт", "Ремонт тяговых электродвигателей", "Ремонт генераторов постоянного тока"],
      en: ["Repair of DC electric motors up to 10 MW", "Repair of traction electric motors", "Repair of DC generators"]
    },
    link: "/services/#service-3",
    image: "electric_motor_direct_current.jpeg"
  },
  {
    title: {
      ua: 'Виготовлення запасних частин',
      ru: 'Изготовление запасных частей',
      en: 'Spare parts manufacturing'
    },
    list: {
      ua: ["Виготовлення секцій обмотки статора для асинхронних, синхронних, й так само низьковольтних електродвигунів", "Виготовлення полюсних котушок для синхронних електродвигунів", "Виготовлення якірних котушок електродвигунів постійного струму", "Виготовлення головних і додаткових полюсів"],
      ru: ["Изготовление секций обмотки статора для асинхронных, синхронных, а так же низковольтных электродвигателей", "Изготовление полюсных катушек для синхронных электродвигателей", "Изготовление якорных катушек электродвигателей постоянного тока", "Изготовление главных и дополнительных полюсов"],
      en: ["Manufacture of stator winding sections for asynchronous, synchronous and low-voltage electric motors", "Manufacture of pole coils for synchronous electric motors", "Manufacture of anchor coils of DC electric motors", "Manufacture of main and additional poles"]
    },
    link: "/services/#service-4",
    image: "electric_motor_parts_manufacturing.jpeg"
  },
  {
    title: {
      ua: 'Ремонт електродвигунів з всипною обмоткою',
      ru: 'Ремонт электродвигателей с всыпной обмоткой',
      en: 'Repair of electric motors with loose winding'
    },
    list: {
      ua: ["Ремонт низьковольтних електродвигунів з короткозамкненим ротором", "Ремонт низьковольтних електродвигунів з фазним ротором", "Ремонт кранових електродвигунів"],
      ru: ["Ремонт низковольтных электродвигателей с короткозамкнутым ротором", "Ремонт низковольтных электродвигателей с фазным ротором", "Ремонт крановых электродвигателей"],
      en: ["Repair of low-voltage electric motors with squirrel-cage rotor", "Repair of low-voltage electric motors with phase rotor", "Repair of crane electric motors"]
    },
    link: "/services/#service-5",
    image: "electric_motor_loose_winding.jpeg"
  },
  {
    title: {
      ua: 'Виїзні роботи',
      ru: 'Выездные работы',
      en: 'Field work'
    },
    list: {
      ua: ['Ремонт великогабаритних електродвигунів за місцем установки', "Ремонт гідрогенераторів", "Демонтажні та монтажні роботи великих електричних двигунів", "Середні ремонти за місцем установки електродвигунів"],
      ru: ["Ремонт крупногабаритных электродвигателей по месту установки", "Ремонт гидрогенераторов", "Демонтажные и монтажные работы крупных электрических двигателей", "Средние ремонты по месту установки электродвигателей"],
      en: ["Repair of large-sized electric motors at the installation site", "Repair of hydrogenerators", "Dismantling and installation works of large electric motors", "Average repairs at the place of installation of electric motors"]
    },
    link: "/services/#service-6",
    image: "electric_motor_field_work.jpeg"
  },
]

export const SpecializationLocalesTitle =  {
  ru: {
    title: "НАША СПЕЦИАЛИЗАЦИЯ",
    content: ""
  },
  en: {
    title: "OUR SPECIALIZATION",
    content: ""
  },
  ua: {
    title: "НАША СПЕЦІАЛІЗАЦІЯ",
    content: ""
  }
}

export default SpecializationLocales;
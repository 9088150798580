import { useInView } from "react-intersection-observer";

const AnimationHeading = ({
  title = "Heading",
  size = "text-base",
  additionalStyle = "",
  margin = ""
}) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    delay: 500,
    threshold: 1
  });

  return (
    <h2 className={`overflow-hidden ${margin}`} ref={ref}>
      <span className={`inline-block relative transition duration-1000 ease-in-out transform font-medium
        ${size} ${additionalStyle} ${inView ? "translate-y-0 opacity-100" : "translate-y-[40px] opacity-0"} `}>
        {title}
      </span>
    </h2>
  );
};

export default AnimationHeading;
export default
[
  "arcelor_mittal.jpeg",
  "centrenergo.jpeg",
  "dch.jpeg",
  "dtek.jpeg",
  "dyckerhoff.jpeg",
  "ea_main_logo.jpeg",
  "electrotyagmash.jpeg",
  "heidelbergcement.jpeg",
  "interpipe.jpeg",
  "interrao.jpeg",
  "metinvest.jpeg",
  "ostchem.jpeg",
  "unicef.jpeg",
]
import Tabs from "src/components/Tabs/Tabs";
import { useRef, useState } from "react";
import { useRouter } from "next/router";
import AboutCompany from "src/components/Sections/HomePage/AboutUsHome/components/AboutCompany/AboutCompany";
import WhoWeAre from "src/components/Sections/HomePage/AboutUsHome/components/WhoWeAre/WhoWeAre";
import ContactsHome from "src/components/Sections/HomePage/AboutUsHome/components/ContactsHome/ContactsHome";
import AnimationHeading from "src/components/AnimationsTags/AnimationHeading/AnimationHeading";
import AboutUsHomeLocales, { AboutUsHomeLocalesTitle } from "./locales/data";

const AboutUsHome = () => {
  const { locale } = useRouter();
  const ref = useRef(null)

  const tabs = AboutUsHomeLocales.map((item) => item[locale].title)

  // eslint-disable-next-line react/jsx-key
  const listContentTabs = [<AboutCompany />, <WhoWeAre />, <ContactsHome />]
  const [activeTab, setActiveTab] = useState(0)

  const tabContent = listContentTabs.find((_, index) => index === activeTab)

  return (
    <section id="about-us" className="px-4 py-8 xl:py-20" ref={ref}>
      <div className="container mx-auto flex flex-col justify-center items-center">
        <AnimationHeading
          title={AboutUsHomeLocalesTitle[locale].title}
          size="text-2xl xl:text-4xl"
          additionalStyle="lg:text-center uppercase"
          margin="text-center mb-8"
        />
        <div className="w-full xl:h-[400px]">
          {tabContent}
        </div>
        <Tabs
          data={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          ref={ref}
        />
      </div>
    </section>
  );
};

export default AboutUsHome;




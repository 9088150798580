export default [
  {
    ru: {
      title: "О нас",
    },
    ua: {
      title: "Про нас",
    },
    en: {
      title: "About",
    }
  },
  {
    ru: {
      title: "Преимущества",
    },
    ua: {
      title: "Переваги",
    },
    en: {
      title: "Features",
    }
  },
  {
    ru: {
      title: "Контакты",
    },
    ua: {
      title: "Контакти",
    },
    en: {
      title: "Contacts",
    }
  }
];

export const AboutUsHomeLocalesTitle =  {
  ru: {
    title: "О КОМПАНИИ",
    content: ""
  },
  en: {
    title: "ABOUT COMPANY",
    content: ""
  },
  ua: {
    title: "ПРО КОМПАНІЮ",
    content: ""
  }
}
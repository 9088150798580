import Image from 'next/image';
import Overlay from 'src/components/Overlay/Overlay';
import HomeHeaderSlider from 'src/components/Sections/HomePage/Header/components/HomeHeaderSlider/HomeHeaderSlider';
import { useRouter } from 'next/router';
import HeaderImage1 from 'public/assets/home_hero.jpeg';
import HeaderImage2 from 'public/assets/home_hero_1.jpeg';
import HeaderImage3 from 'public/assets/home_hero_2.jpeg';
import HeaderImage4 from 'public/assets/home_hero_3.jpeg';
import { useEffect, useState } from 'react';
import { useSpring, animated, easings } from 'react-spring';

const Header = () => {
  const { locale } = useRouter();
  const [currentImg, setCurrentImg] = useState(0);
  const images = {
    0: HeaderImage1,
    1: HeaderImage2,
    2: HeaderImage3,
    3: HeaderImage4,
  };

  const [styles, api] = useSpring(() => ({
    to: { opacity: 1 },
    from: { opacity: 0.2 },
    config: {
      duration: 1000,
      easing: easings.easeInOutSine,
    },
  }));

  const TitleData = {
    ru: {
      content_1: 'ЭЛЕКТРОПРОМРЕМОНТ',
      content_2: 'ДВИГАТЕЛЬ ВАШЕГО БИЗНЕСА',
    },
    en: {
      content_1: 'ELEKTROPROMREMONT',
      content_2: 'ENGINE OF YOUR BUSINESS',
    },
    ua: {
      content_1: 'ЕЛЕКТРОПРОМРЕМОНТ',
      content_2: 'ДВИГУН ВАШОГО БІЗНЕСУ',
    },
  };

  useEffect(() => {
    api.start({
      to: { opacity: 1 },
      from: { opacity: 0.2 },
      config: {
        duration: 1000,
        easing: easings.easeInOutSine,
      },
    });
  }, [api, currentImg]);

  return (
    <section className="w-full h-screen relative text-white flex items-center">
      <animated.div
        style={styles}
        className="w-full h-full absolute"
        key={currentImg}
      >
        <Image
          src={images[currentImg] ?? HeaderImage1}
          alt="Factory"
          layout="fill"
          objectFit="cover"
          priority
          placeholder="blur"
        />
      </animated.div>

      <Overlay />
      <div className="container mx-auto px-4 lg:px-0 relative flex w-full flex-col xl:flex-row xl:pt-10">
        <div className="w-full xl:w-2/4 my-20 xl:my-0">
          <h1 className="text-center xl:text-left">
            <span className="block text-sm minmax-s:text-base md:text-3xl mb-2 font-light tracking-wider">
              {TitleData[locale].content_2}
            </span>
            <span className="block text-xl minmax-s:text-2xl md:text-5xl font-medium">
              {TitleData[locale].content_1}
            </span>
          </h1>
        </div>

        <div className="w-full xl:w-2/4">
          <HomeHeaderSlider setCurrentImg={setCurrentImg} />
        </div>
      </div>
    </section>
  );
};

export default Header;

// absolute top-2/4 left-2/4 transform -translate-x-2/4 -translate-y-2/4

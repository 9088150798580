const Items = {
  ru: [
    {
      title: 'ДИАГНОСТИРУЕМ',
      content: 'Детально диагностируем и анализируем техническое состояние электродвигателей',
      link: '/about_company/#test-station',
    },
    {
      title: 'МОДЕРНИЗИРУЕМ',
      content: 'Вдыхаем новую жизнь и увеличиваем ресурс в устаревшие электродвигатели',
      link: '/about_company/#production',
    },
    {
      title: 'ИЗГОТАВЛИВАЕМ',
      content: 'Изготавливаем запасные части и комплектующие к электрическим двигателям любой сложности',
      link: '/services/#service-4',
    },
    {
      title: 'РЕМОНТИРУЕМ',
      content: 'Выполняем капитальные, средние и текущие ремонты электродвигателей',
      link: '/about_company',
    },
  ],
  en: [
    {
      title: 'DIAGNOSE',
      content: 'Diagnose and analyze the technical condition of electric motors in detail',
      link: '/about_company/#test-station',
    },
    {
      title: 'UPGRADE',
      content: 'We breathe new life and increase the resource in obsolete electric motors',
      link: '/about_company/#production',
    },
    {
      title: 'MANUFACTURE',
      content: 'We manufacture spare parts and components for electric motors of any complexity',
      link: '/services/#service-4',
    },
    {
      title: 'REPAIR',
      content: 'We carry out capital, average and current repairs of electric motors',
      link: '/about_company',
    },
  ],
  ua: [
    {
      title: 'ДІАГНОСТУЄМО',
      content: 'Детально діагностуємо і аналізуємо технічний стан електродвигунів',
      link: '/about_company/#test-station',
    },
    {
      title: 'МОДЕРНІЗУЄМО',
      content: 'Вдихаємо нове життя і збільшуємо ресурс в застарілі електродвигуни',
      link: '/about_company/#production',
    },
    {
      title: 'ВИГОТОВЛЯЄМО',
      content: 'Виготовляємо запасні частини та комплектуючі до електричних двигунів будь-якої складності',
      link: '/services/#service-4',
    },
    {
      title: 'РЕМОНТУЄМО',
      content: 'Виконуємо капітальні, середні та поточні ремонт електродвигунів',
      link: '/about_company',
    },
  ]
};

export default Items;

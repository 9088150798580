import { useEffect, useState } from 'react';

const CountUp = ({ start = 0, end = 1000, delay = 1000, step = 1 }) => {
  const [counter, setCounter] = useState(start);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(counter + step < end) {
        setCounter(counter + step)
      } else {
        setCounter(end)
      }
    }, delay);
    return () => {
      clearInterval(intervalId)
    }
  }, [counter, delay, end, step]);

  return (
    <>
      {counter}
    </>
  );
};

export default CountUp;
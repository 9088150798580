import { useRouter } from "next/router";
import { memo } from "react";
import ButtonAsLink from "src/components/ButtonAsLink/ButtonAsLink";
import { useSpring, animated } from 'react-spring'
import data from "./locales/data";


const AboutCompany = () => {
  const { locale } = useRouter();
  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 200,
    config: { duration: 1000 }
  });

  return (
      <animated.div style={props} className="w-full flex flex-col  justify-center items-center">
        <div className="w-full lg:w-3/4 flex flex-col justify-center text-center">
          <p className="py-2 font-semibold text-base md:text-2xl">{data.locales[locale].content_1}</p>
          <p className="text-base md:text-xl mb-4">{data.locales[locale].content_2}</p>
          <ButtonAsLink
            link="/about_company"
            styling="secondary_reverse"
            additionalStyle="mx-auto"
          />
        </div>
      </animated.div>

  )
};

export default memo(AboutCompany);




import { useEffect, useState } from 'react';
import { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Arrow from 'public/assets/icons/next.svg';
import Image from 'next/image';
import Overlay from 'src/components/Overlay/Overlay';
import { useRouter } from 'next/router';
import ButtonAsLink from 'src/components/ButtonAsLink/ButtonAsLink';
import AnimationHeading from 'src/components/AnimationsTags/AnimationHeading/AnimationHeading';
import AnimationParagraph from 'src/components/AnimationsTags/AnimationParagraph/AnimationParagraph';
import { useSpring, animated } from 'react-spring';
import SpecificityLocales, { SpecificityLocalesTitle } from './locales/data';

const SpecificitySecond = () => {
  const { locale } = useRouter();
  const [activeItem, setActiveItem] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [fadeIn, set] = useSpring(() => ({
    opacity: 0,
    transform: 'translateY(100%)',
  }));

  useEffect(() => {
    set({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(100%)' },
      config: { duration: 700 },
    });
  }, [set, activeItem]);

  return (
    <section className="px-4 py-12 xl:py-20 mt-6">
      <div className="container mx-auto">
        <AnimationHeading
          title={SpecificityLocalesTitle[locale].title}
          size="text-2xl xl:text-4xl"
          additionalStyle="lg:text-center uppercase"
          margin="text-center mb-8"
        />
        <AnimationParagraph additionalStyle="mb-10 text-center">
          {SpecificityLocalesTitle[locale].content}
        </AnimationParagraph>

        <div className="w-full py-100 specificity-mobile relative block lg:hidden">
          <Swiper
            onSwiper={setSwiperInstance}
            modules={[Autoplay]}
            loop
            centeredSlides
            spaceBetween={20}
            slidesPerView={3}
            speed={1000}
            autoplay
            onSlideChange={(swiper) => setActiveItem(swiper.realIndex)}
          >
            {SpecificityLocales.map((item, idx) => (
              <SwiperSlide key={item.title[locale]}>
                <div
                  className={`${
                    idx === activeItem && 'scale-x-[200%] scale-y-[120%]'
                  }
                   w-full h-[200px] relative transition ease-out duration-500 rounded-3xl overflow-hidden`}
                >
                  <Image
                    src={`/assets/sections/home/specificity/${item.image}`}
                    alt=""
                    layout="fill"
                    objectFit="cover"
                  />
                  <Overlay opacity="bg-opacity-40" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="w-full text-center min-h-[240px]">
            <div className="w-full overflow-hidden">
              <animated.div style={fadeIn} className="">
                <p className="font-medium text-2xl">
                  {SpecificityLocales[activeItem].title[locale]}
                </p>
                <p>{SpecificityLocales[activeItem].content[locale]}</p>
              </animated.div>
            </div>
            <ButtonAsLink
              link={SpecificityLocales[activeItem].link}
              styling="secondary_reverse"
              additionalStyle="mx-auto my-2"
            />
          </div>
        </div>

        <div className="w-full py-100 specificity relative hidden lg:block">
          <button
            type="button"
            className="absolute top-40 right-44 z-10 rotate-180 w-12 h-12 border-2 rounded-md border-secondary text-secondary
            flex items-center justify-center hover:bg-secondary hover:text-white"
            onClick={() => swiperInstance.slidePrev()}
            aria-label="previous slide"
          >
            <span className="h-5 w-5">
              <Arrow />
            </span>
          </button>
          <button
            type="button"
            className="absolute top-40 right-24 z-10 w-12 h-12 border-2 rounded-md border-secondary text-secondary
             flex items-center justify-center hover:bg-secondary hover:text-white"
            onClick={() => swiperInstance.slideNext()}
            aria-label="next slide"
          >
            <span className="h-5 w-5">
              <Arrow />
            </span>
          </button>

          <div className="absolute max-w-[400px] top-0 left-0 z-10">
            <div className="w-full overflow-hidden">
              <animated.div style={fadeIn} className="">
                <p className="font-medium text-2xl">
                  {SpecificityLocales[activeItem].title[locale]}
                </p>
                <p>{SpecificityLocales[activeItem].content[locale]}</p>
              </animated.div>
            </div>
            <ButtonAsLink
              link={SpecificityLocales[activeItem].link}
              styling="secondary_reverse"
              additionalStyle="mx-auto my-2"
            />
          </div>
          <Swiper
            onSwiper={setSwiperInstance}
            loop
            centeredSlides
            spaceBetween={20}
            slidesPerView={5}
            autoplay={false}
            onSlideChange={(swiper) => setActiveItem(swiper.realIndex)}
          >
            {SpecificityLocales.map((item, idx) => (
              <SwiperSlide key={item.title[locale]}>
                <div
                  className={`${
                    idx === activeItem &&
                    'scale-[200%] translate-y-[-30%] translate-x-[20%]'
                  }
                   w-full h-[250px] relative transition ease-out duration-500 rounded-3xl overflow-hidden`}
                >
                  <Image
                    src={`/assets/sections/home/specificity/${item.image}`}
                    alt=""
                    layout="fill"
                    objectFit="cover"
                  />
                  <Overlay
                    opacity={`${
                      idx === activeItem ? 'bg-opacity-20' : 'bg-opacity-60'
                    } transition duration-1000`}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default SpecificitySecond;

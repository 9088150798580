import { useRouter } from "next/router";
import { animated, useSpring } from 'react-spring'
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import ButtonAsLink from "src/components/ButtonAsLink/ButtonAsLink";
import Image from "next/image";
import Overlay from "src/components/Overlay/Overlay";
import AnimationHeading from "src/components/AnimationsTags/AnimationHeading/AnimationHeading";
import SpecializationsLocales, { SpecializationLocalesTitle } from "./locales/data";


const SpecializationItem = ({ order, data}) => {
  const [isHovered, setIsHovered] = useState(false);
  const { locale } = useRouter();
  const divStyle = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 250 * order,
    config: { duration: 500 }
  })

  return (
    <animated.div
      style={divStyle}
      className="relative rounded-3xl overflow-hidden p-4 h-[28rem] lg:h-[23rem] flex flex-col"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image src={`/assets/sections/home/our_specialization/${data.image}`} alt="Engine" layout="fill" objectFit="cover" />
      <Overlay opacity={isHovered ? 'bg-opacity-90' : 'bg-opacity-50'} />
      <h3 className={`relative transition-all duration-500 ease-linear text-center mb-4 text-white font-medium text-2xl
      ${isHovered ? 'top-0' : 'top-1/2 -translate-y-2/4'}`}
      >
        {data.title[locale]}
      </h3>
      {isHovered && (
        <div className="fadeIn flex flex-col flex-auto justify-between text-sm">
          <ul className="list-outside pl-6 text-white font-medium">
            {data.list[locale].map((listItem) => (
              <li key={listItem} className="list-disc">
                {listItem}
              </li>
            ))}
          </ul>
          <ButtonAsLink
            link={data.link}
            styling="secondary_reverse"
            additionalStyle="mx-auto my-2"
          />
        </div>
      )}
    </animated.div>
  )
}


const OurSpecialization = () => {
  const { locale } = useRouter();
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 1
  });

  return (
    <section className="px-4 py-8 xl:py-20">
      <div ref={ref}  className="container mx-auto px-4">
        <AnimationHeading
          title={SpecializationLocalesTitle[locale].title}
          size="text-2xl xl:text-4xl"
          additionalStyle="lg:text-center uppercase"
          margin="text-center mb-8"
        />
      {inView && (
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {SpecializationsLocales.map((item, index) => (
            <SpecializationItem
              key={item.title[locale]}
              order={index + 1}
              data={item}
            />
          ))}
        </div>
      )}
      </div>
    </section>
  );
};

export default OurSpecialization;


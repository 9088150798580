import React from 'react';
import CountUp from "src/components/CountUp/CountUp";
import { useRouter } from "next/router";

const StatisticItem = ({ item: { start, end, step, content }}) => {
  const { locale } = useRouter();

  return (
    <li className="w-56 text-center mb-4 lg:mb-0 mx-auto">
      <p className="text-6xl text-secondary font-semibold p-0 drop-shadow-text">
        <CountUp start={start} end={end} step={step} delay={10}/>
      </p>
      <p className="font-semibold text-sm uppercase p-0">{content[locale]}</p>
    </li>
  )
};

export default StatisticItem;
import Head from 'next/head'
import Header from "src/components/Sections/HomePage/Header/Header";
import StatisticsCount from "src/components/Sections/HomePage/StatisticsCount/StatisticsCount";
import AboutUsHome from "src/components/Sections/HomePage/AboutUsHome/AboutUsHome";
import OurSpecialization from "src/components/Sections/HomePage/OurSpecialization/OurSpecialization";
import Partners from "src/components/Sections/HomePage/Partners/Partners";
import SpecificitySecond from "src/components/Sections/HomePage/SpecificitySecond/SpecificitySecond";
import { useRouter } from "next/router";

const headData = {
  ru: {
    title: "ЭЛЕКТРОПРОМРЕМОНТ",
    description: 'Капитальные, средние и текущие ремонты электродвигателей всех мощностей и конструкций, изготовление запасных частей и диагностика электрооборудования'
  },
  en: {
    title: "ELEKTROPROMREMONT",
    description: 'Overhaul, medium and current repairs of electric motors of all capacities and structures, manufacture of spare parts, and diagnostics of electrical equipment'
  },
  ua: {
    title: "ЕЛЕКТРОПРОМРЕМОНТ",
    description: 'Капітальні, середні та поточні ремонти електродвигунів всіх потужностей і конструкцій, виготовлення запасних частин і діагностика електрообладнання'
  }
};

export default function Home() {
  const { locale } = useRouter();
  return (
    <>
      <Head>
        <title>{headData[locale].title}</title>
        <meta name="description" content={headData[locale].description} />
        <meta property="og:title" content={headData[locale].title} />
        <meta property="og:description" content={headData[locale].description} />
        <meta property="og:image" content="/assets/favicons/og_title.jpeg" />
      </Head>
      <Header />
      <AboutUsHome />
      <OurSpecialization />
      <StatisticsCount />
      <SpecificitySecond />
      <Partners />
    </>
  )
}

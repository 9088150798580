import IconDepartment from "public/assets/sections/home/who_we_are/engineer.svg"
import IconISO from "public/assets/sections/home/who_we_are/iso.svg"
import IconCollaboration from "public/assets/sections/home/who_we_are/collaboration.svg"
import IconLaboratory from "public/assets/sections/home/who_we_are/repair.svg"
import IconExperience from "public/assets/sections/home/who_we_are/experience.svg"
import IconEarth from "public/assets/sections/home/who_we_are/earth-grid.svg"


export default [
  {
    ru: {
      title: "КОЛОССАЛЬНЫЙ ОПЫТ",
      content: "Ремонтируем электродвигатели с 1998 года",
    },
    ua: {
      title: "КОЛОСАЛЬНИЙ ДОСВІД",
      content: "Ремонтуємо електродвигуни з 1998 року",
    },
    en: {
      title: "TREMENDOUS EXPERIENCE",
      content: "Recreation of documentation and drawings for further repairs",
    },
    icon: <IconExperience />
  },
  {
    ru: {
      title: "СОБСТВЕННАЯ ЛАБОРАТОРИЯ",
      content: "Проведение комплексной диагностики и испытаний. В том числе на территории заказчика",
    },
    ua: {
      title: "ВЛАСНА ЛАБОРАТОРІЯ",
      content: "Проведення комплексної діагностики та випробувань. У тому числі на території замовника",
    },
    en: {
      title: "OWN LABORATORY",
      content: "Conducting comprehensive diagnostics and tests. Including on the territory of the customer",
    },
    icon: <IconLaboratory />
  },
  {
    ru: {
      title: "СЕРТИФИКАТ ISO 9001:2015",
      content: "Наивысшая оценка сертфикации системы управления качеством",
    },
    ua: {
      title: "СЕРТИФІКАТ ISO 9001: 2015",
      content: "Найвища оцінка сертифікації системи управління якістю",
    },
    en: {
      title: "CERTIFICATE ISO 9001:2015",
      content: "Highest rating for quality management system certification",
    },
    icon: <IconISO />

  },
  {
    ru: {
      title: "СОБСТВЕННЫЙ ТЕХНИЧЕСКИЙ И КОНСТРУКТОРСКИЙ ОТДЕЛ",
      content: "Воссоздание документации и чертежей для проведения дальнейших ремонтных работ",
    },
    ua: {
      title: "ВЛАСНИЙ ТЕХНІЧНИЙ ТА КОНСТРУКТОРСЬКИЙ ВІДДІЛ",
      content: "Відтворення документації та креслень для проведення подальших ремонтних робіт",
    },
    en: {
      title: "OWN TECHNICAL AND DESIGN DEPARTMENT",
      content: "Recreation of documentation and drawings for further repairs",
    },
    icon: <IconDepartment />
  },
  {
    ru: {
      title: "КВАЛИФИЦИРОВАННЫЙ, СПЛОЧЕННЫЙ КОЛЛЕКТИВ",
      content: "Более 100 опытнейших специалистов",
    },
    ua: {
      title: "КВАЛІФІКОВАНИЙ, ЗГУРТОВАНИЙ КОЛЕКТИВ",
      content: "Більше 100 найдосвідченіших фахівців",
    },
    en: {
      title: "HIGHLY QUALIFIED, KNIT TEAM",
      content: "Over 100 experienced specialists",
    },
    icon: <IconCollaboration />
  },
  {
    ru: {
      title: "МЕЖДУНАРОДНОЕ СОТРУДНИЧЕСТВО",
      content: "Ремонт двигателей и поставка запчастей в более чем 15 стран мира",
    },
    ua: {
      title: "МІЖНАРОДНЕ СПІВРОБІТНИЦТВО",
      content: "Ремонт двигунів і постачання запчастин в більш ніж 15 країн світу",
    },
    en: {
      title: "INTERNATIONAL COOPERATION",
      content: "Motor repair and spare parts supply in more than 15 countries",
    },
    icon: <IconEarth />
  },

]